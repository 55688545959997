import {
  mallHomeGoodsListApi,
  getUserInfoApi,
  carListApi,
} from "@/network/api.js";
export default {
  //获取 刷新用户信息
  async ReFreshUserInfo(context) {
    console.log("获取用户信息", context);
    const res = await getUserInfoApi();
    localStorage.setItem("userInfo", JSON.stringify(res.data));
    context.commit("setUserInfo", res.data);
  },
  // 获取首页商品列表，里面含有分类
  async getHomeGoods(context, data) {
    const res = await mallHomeGoodsListApi(data);
    context.commit("setHomeGoods", res.data);
  },
  // 获取购物车列表
  async getCartList(context) {
    const res = await carListApi();
    context.commit("setCarList", res.data?.Items);
  },
  setTokenSync(context, data) {
    return new Promise((resolve) => {
      context.commit("setToken", data);
      context.dispatch("ReFreshUserInfo");
      resolve(true);
    });
  },
};
