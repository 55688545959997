import originAxios from "axios";
import { Message } from "element-ui";
import store from "@/store";
// import common from "../utils/common";
let isShowMsg = true;
const envBaseURL = process.env.VUE_APP_BASE_URL;
export default function axios(option) {
  const token = localStorage.getItem("token");
  const {
    noBaseUrl = "",
    deepParse = true,
    timeout = 120000,
    isShowError = true,
  } = option;
  let baseURL = noBaseUrl ? "" : envBaseURL;
  const params = {
    baseURL,
    timeout,
  };
  return new Promise((resolve, reject) => {
    const instance = originAxios.create(params);
    // 请求拦截处理
    instance.interceptors.request.use(
      (config) => {
        token && (config.headers.Authorization = token);
        // let getUrl = common.qianming({
        //   data: config.data,
        //   method: config.method,
        //   url: config.url,
        // });
        // 获取token
        config.headers = {
          "Cache-Control": "no-cache",
          "Content-Type": option["Content-Type"],
          // 添加到请求头上方
          token: token,
          // "CsgoAtm-Timestamp": getUrl.timestamp,
          // "CsgoAtm-Nonce": getUrl.nonce,
          // "CsgoAtm-Sign": getUrl.sign,
        };
        return config;
      },
      (err) => {
        return err;
      }
    );
    // 返回拦截处理
    instance.interceptors.response.use((res) => {
      let isError = false;
      let msg = "Network Error";
      if (res.status != 200) {
        isError = true;
      }
      if (deepParse && res.data?.code !== 1) {
        isError = true;
        msg = res.data.msg || "System exception";
      }

      if (isError) {
        if (isShowError) {
          if (isShowMsg) {
            Message.error(msg);
            isShowMsg = false;
            setTimeout(() => {
              isShowMsg = true;
            }, 500);
          }
        }
        return Promise.reject(res);
      }
      return res.data;
    });
    //传入对象进行网络请求
    instance(option)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (`${err}`.indexOf("status code 401") > -1) {
          Message.error("Not logged in or expired, please log in again");
          localStorage.clear();
          store?.commit("setUserInfo", {});
          store?.commit("clearToken", null);
        }
        reject(err);
      });
  });
}
