// import {Message} from "element-ui";
var md5 = require("md5");
/**
 * 生成随机的字符串
 * */
let str = "tmskins.com&MessagePush*Key";
// 生成十六问的随机字符串
function randomString(len = 16) {
  //随机值
  var $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678";
  //随机的长度
  var maxPos = $chars.length;
  //暂时定义为空的
  var str = "";
  //循环拼接
  for (var i = 0; i < len; i++) {
    str += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return str;
}

// 签名
function qianming(config) {
  // const{url,data.methed}=config
  // 提取url 由于get请求会有？后面的参数所以需要裁切一下
  let i = config.url.indexOf("?");
  // 保存url
  let url = "";
  // 如果有？ 裁切前面的
  if (i != -1) {
    url = config.url.slice(0, i);
  } else {
    // 没有直接等于这个url
    url = config.url;
  }
  let tiem = new Date();
  // 获取时间戳
  let t = (tiem.getTime() / 1000).toFixed(0);
  //整理数据
  let QM = {
    // 时间戳
    timestamp: t,
    // 随机字符串 32位
    nonce: randomString(32),
    // 签名
    secretKey: "KVEa5kxk5C4CihD6XUFIi81bNSID60714",
  };
  //整理成 可使用的数据传给后端
  // config.method.toUpperCase()请求转大写
  // url请求的域名
  // QM.timestamp 时间戳
  // QM.nonce 随机字符串 32位
  // config.method.toUpperCase()判断是不是GET请求   如果是 \n 否则 在判断 这个post请求体如果是真的就传输这个请求体否则 \n
  // QM.secretKey 签名
  let signature = `${config.method.toUpperCase()}\n/api${url}\n${
    QM.timestamp
  }\n${QM.nonce}\n${
    config.method.toUpperCase() == "GET"
      ? "\n"
      : config.data
      ? JSON.stringify(config.data) + "\n"
      : "\n"
  }${QM.secretKey}\n`;
  // 进行md5加密
  let encryption = md5(signature);
  // 然后拼接到字符串上
  let getUrl = {
    timestamp: QM.timestamp,
    nonce: QM.nonce,
    sign: encryption,
  };
  return getUrl;
}

// MD5加密
function md5jiami(client_id) {
  //随机字符串
  let randomStr = randomString();
  // 获取时间戳
  var timestamp = Date.parse(new Date());
  //  当前时间的时间戳
  let tiem = timestamp.toString().substr(0, 10);
  let app_id = "tmskins.com";
  let token = client_id + randomStr + tiem + app_id + str;
  //md5加密
  let md5Token = md5(token);
  return {
    randomStr,
    tiem,
    md5Token,
  };
}

// 邮箱校验
export function validateEmail(email) {
  // 正则表达式校验邮箱格式
  var re = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
  return re.test(email);
}

// 时间处理封装
//时间戳转换为标准时间
/**
 * 参数一 : 时间戳  timestamp: 1659252290626
 * 参数二 : 需要转换的格式  yyyy/MM/dd hh:mm:ss
 * 返回值 : 根据格式生成的时间
 */
export const formatTime = (timestamp, fmtString) => {
  // 1. 获取时间对象
  const data = new Date(timestamp); // Wed Aug 24 2022 14:44:36 GMT+0800 (中国标准时间)
  // 2. 时间转换
  const dataO = {
    // 匹配 yyyy  =>  年份
    "y+": data.getFullYear(),
    // 匹配 MM  =>  月份
    "M+": data.getMonth() + 1,
    // 匹配 dd  =>  日
    "d+": data.getDate(),
    // 匹配 hh  =>  时
    "h+": data.getHours(),
    // 匹配 mm  =>  分
    "m+": data.getMinutes(),
    // 匹配 ss  =>  秒
    "s+": data.getSeconds(),
  };
  for (const key in dataO) {
    // 拿到对应的正则字符串
    const timeRegex = new RegExp(key, "g");
    // 看是否需要匹配
    if (timeRegex.test(fmtString)) {
      // 小于两位数的，用 0 在前方补齐
      const value = ("" + dataO[key]).padStart(2, "0");
      // 把匹配到的位置用数值来替换
      fmtString = fmtString.replace(timeRegex, value);
    }
  }
  return fmtString;
};
export default {
  md5jiami,
  randomString,
  qianming,
};
