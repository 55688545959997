import Vue from "vue";
import VueRouter from "vue-router";
// import myMixin from "./mixin";
// import myParate from "./parate";
import { Message } from "element-ui";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法，解决vue路由重复导航错误
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "WrapIndex",
    component: () => import("@/views/WrapIndex/index.vue"),
    redirect: "/Home",
    children: [
      // ...myMixin,
      // ...myParate,
      {
        path: "/Home",
        name: "Home",
        component: () => import("@/views/Home/index.vue"),
      },
      {
        path: "/Mall",
        name: "Mall",
        component: () => import("@/views/Mall/index.vue"),
      },
      {
        path: "/MallDetail",
        name: "MallDetail",
        component: () => import("@/views/Mall/mallDetail.vue"),
      },
      {
        path: "/New",
        name: "New",
        component: () => import("@/views/New/index.vue"),
      },
      {
        path: "/Newdetail",
        name: "Newdetail",
        component: () => import("@/views/New/newDetail.vue"),
      },
      {
        path: "/Car",
        name: "Car",
        component: () => import("@/views/Mall/car.vue"),
      },
      {
        path: "/IntegralMall",
        name: "IntegralMall",
        component: () => import("@/views/IntegralMall/index.vue"),
      },
      {
        path: "/User",
        name: "User",
        component: () => import("@/views/User/index.vue"),
      },
      {
        path: "/rollDetail",
        name: "rollDetail",
        component: () => import("@/views/Roll/rollDetail.vue"),
      },
      {
        path: "/Roll",
        name: "Roll",
        component: () => import("@/views/Roll/index.vue"),
      },
      {
        path: "/CheckKeys",
        name: "CheckKeys",
        component: () => import("@/views/CheckKeys/index.vue"),
      },
    ],
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("@/views/Login/login.vue"),
  },
  {
    path: "/Register",
    name: "Register",
    component: () => import("@/views/Login/register.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// 导航守卫
// router.beforeEach((to, from, next) => {
//   if (
//     to.path === "/Warehouse" ||
//     to.path === "/about" ||
//     to.path === "/Extension" ||
//     to.path === "/Recharge" ||
//     to.path === "/CreatingRooms" ||
//     to.path === "/WelfareInfo"
//   ) {
//     let token = sessionStorage.getItem("token");
//     if (token === null || token === "") {
//       next("/Home");
//       Message({
//         message: "您还未登陆，请先登陆!",
//         type: "error",
//       });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default router;
