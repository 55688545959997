export default {
  setDeviceType(state, isMinDevice) {
    state.isMinDevice = isMinDevice;
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
    console.log("获取到的用户信息", state.userInfo);
  },
  setHomeGoods(state, list) {
    state.homeGoods = list;
  },
  setLanguage(state, lang) {
    state.language = lang;
  },
  setToken(state, token) {
    localStorage.setItem("token", token);
    state.token = token;
  },
  clearToken(state) {
    localStorage.setItem("token", "");
    state.token = "";
  },
  setCarList(state, list) {
    state.carList = list;
  },
  setClickFuckInfoShow(state, flag) {
    state.clickFuckInfoShow = flag;
  },
  setShowTipPopup(state, flag) {
    state.showTipPopup = flag;
  },
};
