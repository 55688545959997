import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations.js";
import actions from "./actions.js";

Vue.use(Vuex);

const state = {
  onlineUrl: "https://api.acekeys.net",
  isMinDevice: false, // 是否是375以下用户
  userInfo: "",
  homeGoods: [],
  // 0首页中部，1首页底部，2商城顶部，3积分商城顶部， 4在线检测，5新闻列表，6新闻详情，7购物车结账,8roll
  funcImgs: [
    "/uploads/20231122/ed93d542796e627043ad37233cd661d5.png",
    "/uploads/20231127/b1d012bd55ade6cf94a192fe6f3c6924.png",
    "/uploads/20240529/eafdccdda2e549eacd0fae5f4f87bd60.png",
    "/uploads/20231123/1427c808d7108222a2ac667588445218.png",
    "/uploads/20231123/593d034aac538c67c888b6db67c1ea34.png",
    "/uploads/20231123/c557108b88ea3097e54bedd534225331.png",
    "/uploads/20231123/c557108b88ea3097e54bedd534225331.png",
    "/uploads/20231123/d314eb8447b370e040f5de9e79ea009d.png",
    "/uploads/20240112/3e98efb3f79fe80d8a8e8699ac9527b0.png",
  ],
  mbfuncImgs: [
    "/uploads/20231122/ed93d542796e627043ad37233cd661d5.png",
    "/uploads/20231127/b1d012bd55ade6cf94a192fe6f3c6924.png",
    "/uploads/20240530/faebbd5a796b5c78cb680c954121a791.png",
    "/uploads/20231209/07036dd3de0ee6192da0b1524dae76c5.png",
    "/uploads/20231209/f2a9829977da7e052065d5959f70fe2e.png",
    "/uploads/20231209/7734ad68255497517a903cc64acd28fa.png",
    "/uploads/20231209/7734ad68255497517a903cc64acd28fa.png",
    "/uploads/20231211/400c0861a276b9ce28c0fd67d4256d2b.png",
    "/uploads/20240112/ad1c13fefa3a902d79731880be08b468.png",
  ],
  language: "en", //en英文,ru俄罗斯,ch中文
  token: "",
  carList: [],
  clickFuckInfoShow: false,
  showTipPopup: false,
};

const getters = {
  isLogin(state) {
    return !!state.token;
  },
  isVip(state) {
    return !!state.userInfo.is_vip;
  },
  finalFuncImgs(state) {
    if (state.isMinDevice) {
      return state.mbfuncImgs;
    } else {
      return state.funcImgs;
    }
  },
};
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});
