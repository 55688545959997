import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VueLazyload from "vue-lazyload"; //图片懒加载
import VueClipboard from "vue-clipboard2"; //复制文本
// import echarts from "echarts"; //引入echarts
import Rem from "@/utils/rem.js"; //px转rem文件
import { formatTime } from "@/utils/common.js";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import Vant from "vant";
import "vant/lib/index.css";

import VueI18n from "vue-i18n";
import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
// import jaLocale from "element-ui/lib/locale/lang/ja";
import ruLocale from "element-ui/lib/locale/lang/ru-RU";
import ElementLocale from "element-ui/lib/locale";
// //elementUI翻译程序
Vue.use(VueI18n);
Vue.use(ElementUI, { locale });
const messages = {
  en: {
    message: "hello",
    ...enLocale,
  },
  zh: {
    message: "你好",
    ...zhLocale,
  },
  ru: {
    message: "你好",
    ...ruLocale,
  },
};
const i18n = new VueI18n({
  locale: "en", // set locale
  messages, // set locale messages
});
ElementLocale.i18n((key, value) => i18n.t(key, value));

// import MySocket from "./network/socket";
// Vue.use(MySocket);

Rem(1920, 3000, 0);
Vue.prototype.$formatTime = formatTime;
// Vue.prototype.$echarts = echarts;
// Vue.config.productionTip = false;

//element
Vue.use(ElementUI);

//vant
Vue.use(Vant);

//复制文本
Vue.use(VueClipboard);

// Vue.use(VueLazyload, {
//   loading: require("@/assets/images/loading-svg/loading-bars.svg"),
//   error: require("@/assets/images/other/none.jpg"),
// });
import Cselect from "@/components/common/Cselect.vue";
Vue.component("Cselect", Cselect);
// 解决定位显示为顶部问题
// router.afterEach((to, from, next) => {
//   window.scrollTo(0, 0);
// });
// 正式环境清除所有console.log
if (process.env.NODE_ENV === "production") {
  if (window) {
    window.console.log = function () {};
  }
}
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
